<template>
  <iframe :src="getIframeSrc()" class="iframe-class"></iframe>
</template>

<script>
import { resumeSession } from '@/utils/Auth';
import { isJson } from '@/utils/helpers';
import { brandUserServiceRedirectURL, envVariables } from '../utils/helpers';

export default {
  name: 'MiddlewarePage',
  data() {
    return {
      env: process.env,
      redirect: '',
    };
  },
  methods: {
    getIframeSrc: () => {
      const baseDomain = brandUserServiceRedirectURL(envVariables.VUE_APP_SESSION_BASE_DOMAIN);

      return `${baseDomain}/user/session`;
    },
    async onMessage(event) {
      const postedMessage = typeof event.data === 'string' && isJson(event.data) ? JSON.parse(event.data) : event.data;

      if (!!postedMessage.type && postedMessage.type.includes('sso')) {
        window.removeEventListener('message', this.onMessage);
        if (postedMessage.payload.error) {
          this.redirectSignIn();
        } else if (postedMessage.payload.refreshToken) {
          await resumeSession(postedMessage.payload.refreshToken);
          await this.$store.dispatch('fetchNaturalUser').catch((error) => {
            this.redirectSignIn();
          });
          await this.$store.dispatch('fetchOnboardingStatus');

          this.$router.push(this.redirect);
        }
      }
    },

    redirectSignIn() {
      this.$router.push({
        name: 'SignIn',
        query: { redirectURL: `${window.location.origin}${window.location.pathname}#${this.$route.query.to}` },
      });
    },
  },
  mounted() {
    this.redirect = this.$route.query.to;

    try {
      window.addEventListener('message', this.onMessage);
    } catch (e) {
      console.log(e);
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage);
  },
};
</script>

<style lang="scss" scoped>
.iframe-class {
  display: none;
}
</style>
